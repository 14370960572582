#three-steps-setup {
  @apply bg-gray-100 pt-16 px-4;

  h2 {
    @apply text-3xl text-center text-gray-700 font-bold;
  }

  h3 {
    @apply flex flex-wrap justify-center .items-center text-2xl text-gray-700 font-bold text-center;
  }

  .section {
    height: 520px;
    display: grid;
    grid-template-columns: 450px 1fr;
    grid-template-rows: auto;
    grid-column-gap: 75px;
    grid-row-gap: 20px;
    max-width: 1005px;
    padding: 3.125rem 1.875rem;
    position: relative;

    .separator {
      @apply w-32 h-1 bg-teal-500 mt-4 mx-auto;
    }

    .step {
      @apply flex justify-around mx-auto items-center text-center mt-8;
      perspective: 1300px;
      perspective-origin: center;
      padding: 0 10%;
      max-width: 1440px;

      .steps {
        transform: scale(1.1);

        .start,
        .finish {
          @apply bg-red-500 text-sm text-white rounded-full py-1 px-2;
        }
      }


      .svg-section-container {
        @apply w-full h-auto;
        flex-basis: 50%;

        .step-svg {
          @apply w-full h-auto;
        }

        .svg-create-farm {
          transform: rotateY(-20deg);
        }

        .svg-create-planification {
          transform: rotateY(20deg);
        }
      }
    }

    @media screen and (max-width:1100px) {
      .step {
        @apply .flex-col;

        .svg-section-container {
          width: 90%;
          max-width: 600px;
          @apply mb-16;

          .svg-create-farm {
            transform: rotateY(0);
          }

          .svg-create-planification {
            transform: rotateY(0);
          }
        }
      }

      .step-create-farm {
        @apply flex-col-reverse;
      }
    }
  }
  .section-enjoy {
    svg {
      max-width: 500px;
    }
  }

  .step-enjoy {
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }

    & li {
      @apply text-gray-700 text-xl py-2;
      width: 30%;
      height: 100px;
      margin: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
    }
  }

  @media screen and (max-width: 950px) {
    .section {
      grid-template-columns: auto !important;
      height: auto !important;
      padding: 3.125rem 0 !important;

      .left-video {
        order: 1;
      }

      div {

        video {
          @apply relative w-full mx-auto;
          height: auto !important;
          max-width: 610px !important;
       }
      }
    }
  }
}
