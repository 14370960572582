header {
  width: 100%;

  .header-container {
    display: grid;
    grid-template-columns: 450px 450px;
    grid-template-rows: auto;
    grid-column-gap: 75px;
    grid-row-gap: 20px;
    max-width: 1100px;
    padding: 3.125rem 1.875rem;
    position: relative;
    min-height: 788px;

    .video-container {
      width: 1200px;
      height: 500px;
      clip-path: inset(0px 1px);
      align-self: center;
    }

    .video-container > video {
      clip-path: inset(2px 0 0 0);
      border-radius: 15px;
    }
  }

   @media screen and (max-width:1350px) {
     .header-container {
       grid-column-gap: 15px;

       .title-first-part {
        font-size: 2.5rem;
      }

      .video-container {
        width: 1000px;
        height: 400px;
      }
    }
  }
   @media screen and (max-width:1100px) {
     .header-container {
       grid-column-gap: 15px;
    }
  }


  @media screen and (max-width:950px) {
    .header-container {
      grid-template-columns: auto;
      max-width: 670px;

      .header-content {
        @apply my-12 w-full;
      }

      .video-container {
        width: 100%;
        height: auto;
        align-self: start;
      }
    }
  }
  @media screen and (max-width:700px) {
    .title-first-part {
      font-size: 2.5rem;
    }
  }

  .signup-button {
    transition: transform 0.15s;
    transform-origin: left;
    background: #057DBB;
  }

  .signup-button:hover {
    transform: scale(0.9);
    box-shadow: none;
  }

  @media screen and (max-width:700px) {
    .sub-header .title {
      @apply text-2xl;
    }
  }
}

@media screen and (min-width:950px) {
  .video-container > video {
    @apply h-full;
  }
}