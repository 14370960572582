nav {
  opacity: 1;
  transition: opacity 0.2s;
  width: 100%;

  .menu-button-container {
    display: none;
    height: 30px;
    width: 30px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .menu {
    display: flex;
  }

  #menu-toggle {
    display: none;
  }

  .menu-button,
  .menu-button::before,
  .menu-button::after {
    display: block;
    @apply bg-gray-800;
    position: absolute;
    height: 3px;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 2px;
  }

  .menu-button {
    width: 30px;
  }

  .menu-button::before {
    content: '';
    margin-top: -8px;
    left: 10px;
    width: 20px;
  }

  .menu-button::after {
    content: '';
    margin-top: 8px;
    left: 5px;
    width: 25px;
  }

  #menu-toggle:checked+.menu-button-container .menu-button::before {
    margin-top: 0px;
    width: 30px;
    left: 0;
    transform: rotate(45deg);
    @apply bg-teal-600;
  }

  #menu-toggle:checked+.menu-button-container .menu-button {
    background: rgba(255, 255, 255, 0);
  }

  #menu-toggle:checked+.menu-button-container .menu-button::after {
    margin-top: 0px;
    width: 30px;
    left: 0;
    transform: rotate(-45deg);
    @apply bg-teal-800;
  }

  .link-container {
    position: relative;
  }

  .link-container:hover {
    @apply bg-gray-300 text-gray-800 rounded-lg cursor-pointer;
    background: rgba(130,130,150,0.2);
  }

  .login-button:hover {
    @apply bg-blue-500 text-white rounded-lg cursor-pointer;
  }

  #login-button-menu {
    display: none;
  }

  @media (max-width: 700px) {
    .menu-button-container {
      display: flex;
    }

    #login-button-menu {
      display: block;
    }

    #login-button-single {
      display: none;
    }

    .menu {
      position: absolute;
      top: 0;
      margin-top: 4rem;
      margin-right: 1rem;
      right: 0;
      flex-direction: column;
      width: 240px;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      padding: 10px;
      background-color: white;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }

    .menu>div {
      padding: 20px;
      width: 100%;
      text-align: center;
    }

    .menu>div:hover {
      border-radius: 15px;
      background-color: #319795;
      color: white;
      font-weight: bold;
      cursor: pointer;
    }

    .menu>#login-button-menu:hover {
      @apply bg-blue-500 text-white;
    }

    #menu-toggle~.menu {
      transform: scale(0);
      transform-origin: top right;
      transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }

    #menu-toggle:checked~.menu {
      transform: scale(1);
    }
  }
}

.hide-shadow {
  background: transparent;
  box-shadow: none;
}