@tailwind base;
@tailwind components;
@tailwind utilities;

@import './styles/nav.scss';
@import './styles/header.scss';
@import './styles/features-section.scss';
@import './styles/three-step-section.scss';
@import './styles/early-access-form.scss';

/* nunito-600 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  unicode-range: U+000-5FF;
  src: local('Nunito'),
    url('./fonts/nunito-v16-latin-600.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./fonts/nunito-v16-latin-600.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: fallback;
}

/* nunito-700 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  unicode-range: U+000-5FF;
  src: local('Nunito'),
    url('./fonts/nunito-v16-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./fonts/nunito-v16-latin-700.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: fallback;
}


input,
textarea,
button,
select,
.menu,
.menu-button-container,
a {
  -webkit-tap-highlight-color: transparent;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Nunito',
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  Roboto,
  Helvetica,
  Arial,
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol";

  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

li {
  list-style: disc;
  list-style-position: inside;
}