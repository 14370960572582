#features {
  @apply text-gray-700 py-8;

  h2 {
    @apply text-3xl text-center text-gray-700 font-bold;
  }

  .features-container {
    .feature {
      height: 500px;
      display: grid;
      grid-template-columns: 450px 450px;
      grid-template-rows: auto;
      grid-column-gap: 75px;
      grid-row-gap: 20px;
      max-width: 1100px;
      padding: 3.125rem 1.875rem;
      position: relative;


      .feature-images-section {
        position: relative;
      }

      .feature-images-wrapper > img,
      .feature-images-wrapper > .feature-image-container {
        max-width:1000px;
        height: 400px;
        transition: opacity 0.2s, transform 0.2s;
      }

      .feature-images-wrapper > .hide {
        opacity: 0;
        transition: opacity 0.2s, transform 0.2s;
        transform: translateY(15px);
      }

      .feature-images-wrapper.left {
        position: absolute;
        right: 0;
        order: 0;
      }
    }

    .feature-title-card {
      max-height: 59px;
      transition: max-height 0.15s;
      will-change: max-height;

      & > p {
        @apply opacity-0 text-white;
        font-weight: 700;
        transition: opacity 0.2s;
      }

      img,
      .images-container {
        display: none;
      }
    }

    .feature-title-card.active {
      max-height: 107px;

      & > p {
        @apply opacity-100;
      }

    }

    .feature-planification > .feature-title-card.active {
      background: #6b9ece;

      h4,
      .feature-card-icon {
        color: #0d1d2d;
      }
    }

    .feature-home > .feature-title-card.active {
      background: #6c72ca;

      h4,
      .feature-card-icon {
        color: #0c0533;
      }
    }

    .feature-analytics > .feature-title-card.active {
      background: #be86c3;

      h4,
      .feature-card-icon {
        color: #412244;
      }
    }
  }

  .features-container {
    & > div {
      @apply my-8;

      ul {
        @apply mx-auto;
        width: 70%;

        @media screen and (max-width:631px) {
          @apply w-full;
        }

        & li:nth-child(odd) {
          @apply bg-gray-100 rounded;
        }

        li {
          @apply p-2;
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .features-container .feature {
    grid-column-gap: 15px !important;
  }
}

@media screen and (max-width: 950px) {
  .features-container .feature {
    grid-template-columns: auto !important;
    height: auto !important;
    padding: 0 0 !important;
    max-width: 642px !important;
  }

  .feature-content {
    @apply px-4;
  }

  .feature-images-wrapper {
    display: none;
  }

  .feature-title-card {
    height: 59px !important;
    max-height: none !important;
    transition: height 0.2s;
  }

  .feature-description {
    @apply pr-8;
  }

  .feature-title-card .images-container > img {
    display: block !important;
  }

  .feature-title-card > img,
  .feature-title-card .images-container {
    display: block !important;
    @apply opacity-0;
    width: 100%;
    max-width: 450px;
    margin: 10px auto;
    box-shadow: none;

  }
  .feature-title-card.active {
    height: auto !important;
    max-height: none !important;

    img,
    .images-container {
      @apply opacity-100;
    }
  }

}